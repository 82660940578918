import React, { useEffect } from 'react';

type Props = {
  heading?: string;
  guideKey?: string;
};

declare global {
  interface Window {
    PREEZIE_GUIDE: any;
  }
}

const PerfectGiftSection = (props: Props) => {
  const { heading, guideKey } = props;

  useEffect(() => {
    if (window.PREEZIE_GUIDE) {
      window.PREEZIE_GUIDE.render([
        {
          guideKey: guideKey
            ? guideKey
            : '42e452e1-7140-463b-a3cb-08d931ef82c8',
          version: '1.0.0',
          renderTo: 'preezie-widget-div-id',
        },
      ]);
    }

    return () => {};
  }, []);

  if (heading) {
    return (
      <div className="bg-[#e8e8e6] mb-8 lg:mb-10">
        <p className="text-center font-playfairdisplay italic text-3xl text-brand-white-hover py-4 border border-brand-primary border-b-0">
          {heading}
        </p>
        <div id="preezie-widget-div-id" className="container"></div>
      </div>
    );
  }

  return (
    <>
      <div id="preezie-widget-div-id" className="container"></div>
    </>
  );
};

export default PerfectGiftSection;
